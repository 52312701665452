/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'Header',
        import: () => import(/* webpackChunkName: "Header" */ '@stories/Widgets/Header/Header'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Footer',
        import: () => import(/* webpackChunkName: "Footer" */ '@stories/Widgets/Footer/Footer'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Accordion',
        import: () => import(/* webpackChunkName: "Accordion" */ '@stories/Widgets/Accordion/Accordion'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ApproachSection',
        import: () => import(/* webpackChunkName: "ApproachSection" */ '@stories/Widgets/ApproachSection/ApproachSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BlockCategoryGrid',
        import: () => import(/* webpackChunkName: "BlockCategoryGrid" */ '@stories/Widgets/BlockCategoryGrid/BlockCategoryGrid'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CaseStudiesSection',
        import: () => import(/* webpackChunkName: "CaseStudiesSection" */ '@stories/Widgets/CaseStudiesSection/CaseStudiesSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MediaListing',
        import: () => import(/* webpackChunkName: "MediaListing" */ '@stories/Widgets/MediaListing/MediaListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContentBlockList',
        import: () => import(/* webpackChunkName: "ContentBlockList" */ '@stories/Widgets/ContentBlockList/ContentBlockList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContactFormSection',
        import: () => import(/* webpackChunkName: "ContactFormSection" */ '@stories/Widgets/ContactFormSection/ContactFormSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContentHeroBanner',
        import: () => import(/* webpackChunkName: "ContentHeroBanner" */ '@stories/Widgets/ContentHeroBanner/ContentHeroBanner'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'ContentIntroSection',
        import: () => import(/* webpackChunkName: "ContentIntroSection" */ '@stories/Widgets/ContentIntroSection/ContentIntroSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContentImageWithCopySection',
        import: () => import(/* webpackChunkName: "ContentImageWithCopySection" */ '@stories/Widgets/ContentImageWithCopySection/ContentImageWithCopySection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CtaSection',
        import: () => import(/* webpackChunkName: "CtaSection" */ '@stories/Widgets/CtaSection/CtaSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'GoogleMapSection',
        import: () => import(/* webpackChunkName: "GoogleMapSection" */ '@stories/Widgets/GoogleMapSection/GoogleMapSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'GraphSectionCarousel',
        import: () => import(/* webpackChunkName: "GraphSectionCarousel" */ '@stories/Widgets/GraphSectionCarousel/GraphSectionCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'GroupCompaniesListing',
        import: () => import(/* webpackChunkName: "GroupCompaniesListing" */ '@stories/Widgets/GroupCompaniesListing/GroupCompaniesListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HomeHeroBanner',
        import: () => import(/* webpackChunkName: "HomeHeroBanner" */ '@stories/Widgets/HomeHeroBanner/HomeHeroBanner'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'HoverExpandSection',
        import: () => import(/* webpackChunkName: "HoverExpandSection" */ '@stories/Widgets/HoverExpandSection/HoverExpandSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageAndCopyHalfTiles',
        import: () => import(/* webpackChunkName: "ImageAndCopyHalfTiles" */ '@stories/Widgets/ImageAndCopyHalfTiles/ImageAndCopyHalfTiles'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageSection',
        import: () => import(/* webpackChunkName: "ImageSection" */ '@stories/Widgets/ImageSection/ImageSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InvestorsSection',
        import: () => import(/* webpackChunkName: "InvestorsSection" */ '@stories/Widgets/InvestorsSection/InvestorsSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InvestorsSummary',
        import: () => import(/* webpackChunkName: "InvestorsSummary" */ '@stories/Widgets/InvestorsSummary/InvestorsSummary'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'IssueCarousel',
        import: () => import(/* webpackChunkName: "IssueCarousel" */ '@stories/Widgets/IssueCarousel/IssueCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MarketCardsSection',
        import: () => import(/* webpackChunkName: "MarketCardsSection" */ '@stories/Widgets/MarketCardsSection/MarketCardsSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MarketIconsSection',
        import: () => import(/* webpackChunkName: "MarketIconsSection" */ '@stories/Widgets/MarketIconsSection/MarketIconsSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MarketSectorsSection',
        import: () => import(/* webpackChunkName: "MarketSectorsSection" */ '@stories/Widgets/MarketSectorsSection/MarketSectorsSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MediaCarousel',
        import: () => import(/* webpackChunkName: "MediaCarousel" */ '@stories/Widgets/MediaCarousel/MediaCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MediaDetailContentSection',
        import: () => import(/* webpackChunkName: "MediaDetailContentSection" */ '@stories/Widgets/MediaDetailContentSection/MediaDetailContentSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NewsletterArticleListing',
        import: () => import(/* webpackChunkName: "NewsletterArticleListing" */ '@stories/Widgets/NewsletterArticleListing/NewsletterArticleListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NextArticleSection',
        import: () => import(/* webpackChunkName: "NextArticleSection" */ '@stories/Widgets/NextArticleSection/NextArticleSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PeopleListingCards',
        import: () => import(/* webpackChunkName: "PeopleListingCards" */ '@stories/Widgets/PeopleListingCards/PeopleListingCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RichText',
        import: () => import(/* webpackChunkName: "RichText" */ '@stories/Widgets/RichText/RichText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SearchResultsSection',
        import: () => import(/* webpackChunkName: "SearchResultsSection" */ '@stories/Widgets/SearchResultsSection/SearchResultsSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SignUpForm',
        import: () => import(/* webpackChunkName: "SignUpForm" */ '@stories/Widgets/SignUpForm/SignUpForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SocialMediaSection',
        import: () => import(/* webpackChunkName: "SocialMediaSection" */ '@stories/Widgets/SocialMediaSection/SocialMediaSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContent',
        import: () => import(/* webpackChunkName: "SplitContent" */ '@stories/Widgets/SplitContent/SplitContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContentList',
        import: () => import(/* webpackChunkName: "SplitContentList" */ '@stories/Widgets/SplitContentList/SplitContentList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'StaffTestimonialSection',
        import: () => import(/* webpackChunkName: "StaffTestimonialSection" */ '@stories/Widgets/StaffTestimonialSection/StaffTestimonialSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'StatisticCarouselSection',
        import: () => import(/* webpackChunkName: "StatisticCarouselSection" */ '@stories/Widgets/StatisticCarouselSection/StatisticCarouselSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'StatisticGlobeSection',
        import: () => import(/* webpackChunkName: "StatisticGlobeSection" */ '@stories/Widgets/StatisticGlobeSection/StatisticGlobeSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TestimonialCarouselSection',
        import: () => import(/* webpackChunkName: "TestimonialCarouselSection" */ '@stories/Widgets/TestimonialCarouselSection/TestimonialCarouselSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TextListSection',
        import: () => import(/* webpackChunkName: "TextListSection" */ '@stories/Widgets/TextListSection/TextListSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VacanciesListing',
        import: () => import(/* webpackChunkName: "VacanciesListing" */ '@stories/Widgets/VacanciesListing/VacanciesListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VideoEmbedSection',
        import: () => import(/* webpackChunkName: "VideoEmbedSection" */ '@stories/Widgets/VideoEmbedSection/VideoEmbedSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LocationMap',
        import: () => import(/* webpackChunkName: "LocationMap" */ '@stories/Widgets/LocationMap/LocationMap'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SiteMap',
        import: () => import(/* webpackChunkName: "SiteMap" */ '@stories/Widgets/SiteMap/SiteMap'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ScrollableSplitContent',
        import: () => import(/* webpackChunkName: "SiteMap" */ '@stories/Widgets/ScrollableSplitContent/ScrollableSplitContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FullWidthQuote',
        import: () => import(/* webpackChunkName: "SiteMap" */ '@stories/Widgets/FullWidthQuote/FullWidthQuote'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'StatisticsList',
        import: () => import(/* webpackChunkName: "SiteMap" */ '@stories/Widgets/StatisticsList/StatisticsList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageHeading',
        import: () => import(/* webpackChunkName: "SiteMap" */ '@stories/Widgets/ImageHeading/ImageHeading'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ScrollingContentBlockList',
        import: () => import(/* webpackChunkName: "SiteMap" */ '@stories/Widgets/ScrollingContentBlockList/ScrollingContentBlockList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ScrollingContentTabs',
        import: () => import(/* webpackChunkName: "SiteMap" */ '@stories/Widgets/ScrollingContentTabs/ScrollingContentTabs'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LargeCarousel',
        import: () => import(/* webpackChunkName: "SiteMap" */ '@stories/Widgets/LargeCarousel/LargeCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InlineImageBlock',
        import: () => import(/* webpackChunkName: "SiteMap" */ '@stories/Widgets/InlineImageBlock/InlineImageBlock'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FullWidthImageWindow',
        import: () => import(/* webpackChunkName: "SiteMap" */ '@stories/Widgets/FullWidthImageWindow/FullWidthImageWindow'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageCarousel',
        import: () => import(/* webpackChunkName: "ImageCarousel" */ '@stories/Widgets/ImageCarousel/ImageCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    // product details/listing
    {
        name: 'ProductListing',
        import: () => import(/* webpackChunkName: "ProductListing" */ '@stories/Widgets/ProductListing/ProductListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProductDetails',
        import: () => import(/* webpackChunkName: "ProductDetails" */ '@stories/Widgets/ProductDetails/ProductDetails'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ApplicationCarousel',
        import: () => import(/* webpackChunkName: "ApplicationCarousel" */ '@stories/Widgets/ApplicationCarousel/ApplicationCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SolutionCards',
        import: () => import(/* webpackChunkName: "SolutionCards" */ '@stories/Widgets/SolutionCardsSection/SolutionCardsSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RelatedProducts',
        import: () => import(/* webpackChunkName: "RelatedProducts" */ '@stories/Widgets/RelatedProducts/RelatedProducts'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ComparisonTable',
        import: () => import(/* webpackChunkName: "RelatedProducts" */ '@stories/Widgets/ComparisonTable/ComparisonTable'),
        config: {
            hydrate: 'in-view',
        },
    },
];
